<template>
  <div class="public_css_module personal_right_box message_page">
    <div class="radioBox">
      <div :class="active == 1 ? 'activeSty' : ''" @click="changeActive(1)">
        <img src="@/assets/images/banner/zhibo.png" alt="">
        全部直播
      </div>
      <div :class="active == 2 ? 'activeSty' : ''" @click="changeActive(2)">
        <img src="@/assets/images/banner/zhuanti.png" alt="">
        全部专题
      </div>
    </div>
    <div class="mainCen" v-if="active == 1">
      <div class="ulBox df dfw" v-if="total">
        <div class="liBox cp mb26" v-for="(item, index) in dataList1" :key="index" @click="goMsLiveDetails(item.id)">
          <div class="imgbox">
            <img class="imgbig" v-if="item.cover_url" :src="item.cover_url" alt="">
            <img v-else class="imgbig" src="@/assets/images/banner/home_news_pic.png" alt="">
            <div class="imgAbout1 tc line-h20" :class="item.status == 1 ? 'bg1' : item.status == 2 ? 'bg2' : 'bg3'">
              {{ item.status_str }}
            </div>
            <div class="imgAbout2 df line-h24">
              <div class="flex mt3 mr5">
                <i class="el-icon-caret-right"></i>
              </div>
              <p>{{ item.start_date }} <i class="ml3 mr3"></i> {{ item.start_time.substring(0, 5) }}~{{
                item.end_time.substring(0, 5) }}</p>
            </div>
          </div>
          <div class="txtBox mt10">
            <h4 class="ellipsis-1 mb10">{{ item.title }}</h4>
            <div class="lableBox df mb10">
              <p v-for="(item, index) in item.label.split(',')" :key="index">{{ item }}</p>
            </div>
            <div class="df dfb">
              <p>{{ item.type == 1 ? '四名沙龙' : item.type == 2 ? '四名论坛' : item.type == 2 ? '竞赛活动' : '四名伴我行' }}</p>
              <p>浏览人次：{{ item.view_count }}</p>
            </div>
          </div>
        </div>
      </div>
      <el-empty :image-size="50" v-else description="暂无数据"></el-empty>
      <el-pagination v-if="total" class="tc mt30 mb30" background @current-change="handleCurrentChange"
        :current-page.sync="form.page" :page-size="8" layout="total, prev, pager, next" :total="total">
      </el-pagination>
    </div>

    <div class="mainZhuan" v-if="active == 2">
      <div class="ulBox df dfw" v-if="total">
        <div class="liBox mb30" v-for="(item, index) in dataList1" :key="index">
          <div class="topBox" :style="{ backgroundImage: 'url(' + item.cover_url + ')' }">
            <h4 class="ellipsis-2 mb15">{{ item.title }}</h4>
            <div class="lableBox df">
              <p v-for="(item, index) in item.label.split(',')" :key="index">{{ item }}</p>
            </div>
          </div>
          <div class="bomm df dfb">
            <h4>{{ item.type_str }}</h4>
            <p class="cp" @click="gomsLiveTopic(item)">{{ item.live_count }}场直播 ></p>
          </div>
        </div>
      </div>
      <el-empty :image-size="50" v-else description="暂无数据"></el-empty>
      <el-pagination v-if="total" class="tc mt30 mb30" background @current-change="handleCurrentChange"
        :current-page.sync="form.page" :page-size="8" layout="total, prev, pager, next" :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import * as  msAllApi from "@/api/teacherStudio/msAllApi"
export default {
  data() {
    return {
      type1List: [
        { id: 0, name: "全部" },
        { id: 1, name: "四名沙龙" },
        { id: 2, name: "四名论坛" },
        { id: 3, name: "竞赛活动" },
        { id: 4, name: "四名伴我行" },
      ],
      type2List: [
        { id: 0, name: "全部" },
        { id: 1, name: "进行中" },
        { id: 2, name: "未开始" },
        { id: 3, name: "已结束" },
      ],
      dataList1: [],
      total: 0,
      form: {
        page: 1,
        limit: 8,
      },
      active: 1,
    };
  },

  created() {
    if (this.$route.query.active) {
      this.active = this.$route.query.active
    }
    this.getList1()
  },
  methods: {
    changeActive(val) {
      this.active = val
      this.dataList1 = []
      this.form = {
        page: 1,
        limit: 8,
      }
      this.getList1()
    },
    changeType(val, name) {
      this.form[name] = val
      this.getList1()
    },
    getList1() {
      if (this.active == 1) {
        msAllApi.myLiveList(this.form).then(res => {
          this.dataList1 = res.data.data.data
          this.total = res.data.data.total
        })
      } else {
        msAllApi.myTopicList(this.form).then(res => {
          this.dataList1 = res.data.data.data
          this.total = res.data.data.total
        })
      }
    },
    handleCurrentChange(val) {
      this.form.page = val
      this.getList1()
    },
    seletBtn() {
      this.form.page = 1
      this.getList1()
    },
    // 跳转直播详情
    goMsLiveDetails(id) {
      this.$router.push({
        name: "MsLiveDetails",
        query: { id: id }
      })
    },
    gomsLiveTopic(item) {
      this.$router.push({
        name: "msLiveTopic",
        query: { id: item.id }
      })
    },
    setText(str) {
      let words = str
        .replace(/<[^<>]+>/g, "")
        .replace(/&nbsp;/gi, "")
        .replace(/&rdquo;/gi, "")
        .replace(/&ldquo;/gi, ""); //这里是去除标签
      return words.replace(/\s/g, ""); //这里是去除空格
    },
  },

  mounted() {
  },
};
</script>

<style lang="less" scoped>
.message_page {
  width: 100%;
  background-color: #fff;
  box-sizing: border-box;
}

.radioBox {
  display: flex;
  border: 1px solid #fff;
  border-bottom: none;
  background: #ebf3fc;
  align-items: center;
  border-radius: 8px 8px 0 0;

  >div {
    width: 50%;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
    color: #666666;
    cursor: pointer;

    &:first-child {
      border-radius: 8px 0 0 0;
    }

    &:last-child {
      border-radius: 0 8px 0 0;
    }

    img {
      width: 26px;
      height: 26px;
      margin-right: 8px;
    }
  }

  .activeSty {
    color: #fff;
    background: linear-gradient(-39deg, rgba(76, 164, 241, 1) 0%, rgba(95, 213, 251, 1) 100%);
  }
}

.mainCen {
  padding: 30px;
  box-sizing: border-box;

  .ulBox {
    .liBox {
      width: 258px;
      height: 248px;
      background: rgba(255, 255, 255, 1);
      border-radius: 8px;
      box-sizing: border-box;
      margin-right: 26px;
      border: 2px solid #eef2fb;
      transition: all 0.3s ease-in-out;

      &:hover {
        transform: translateY(-8px);
        box-shadow: 0 6px 14px 0 rgba(199, 208, 226, 0.68);
      }

      &:nth-child(4n) {
        margin-right: 0;
      }

      .imgbox {
        width: 100%;
        height: 133px;
        border-radius: 8px;
        position: relative;

        img {
          border-radius: 8px;
        }

        .imgAbout1 {
          position: absolute;
          top: 0;
          left: 0;
          width: 52px;
          height: 20px;
          border-radius: 8px 0 8px 0;
          font-size: 12px;
          font-weight: bold;
          color: #ffffff;

          &.bg1 {
            background: #ff7a29;
          }

          &.bg2 {
            background: #51B875;
          }

          &.bg3 {
            background: #999;
          }
        }

        .imgAbout2 {
          position: absolute;
          bottom: 0;
          left: 0;
          background: rgba(0, 0, 0, 0.5);
          width: 100%;
          height: 24px;
          color: #fff;
          font-size: 14px;
          justify-content: center;

          >div {
            width: 14px;
            height: 14px;
            border: 1px solid #fff;
            border-radius: 50%;
          }
        }

      }

      .txtBox {
        padding: 0 20px;
        box-sizing: border-box;

        h4 {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #2a3037;
        }

        .lableBox {
          height: 23px;
          flex-wrap: wrap;
          overflow: hidden;

          p {
            padding: 2px 6px;
            background: #e5f4ff;
            border-radius: 4px;
            margin-right: 10px;
            text-align: center;
            color: #0493ff;
            font-size: 14px;
          }
        }

        div {
          p {
            font-size: 14px;
            color: rgba(153, 153, 153, 1);
          }
        }
      }
    }
  }
}

.mainZhuan {
  padding: 30px 60px;
  box-sizing: border-box;

  .ulBox {
    .liBox {
      width: 500px;
      height: 200px;
      border-radius: 8px;
      background: rgba(255, 255, 255, 1);
      box-sizing: border-box;
      margin-right: 50px;
      border: 2px solid #eef2fb;
      transition: all 0.3s ease-in-out;

      &:hover {
        transform: translateY(-8px);
        box-shadow: 0 6px 14px 0 rgba(199, 208, 226, 0.68);
      }

      &:nth-child(2n) {
        margin-right: 0;
      }

      .topBox {
        width: 100%;
        height: 150px;
        padding: 30px 20px 10px 20px;
        box-sizing: border-box;
        border-radius: 8px 8px 0 0;
        background-size: 100% 100%;

        h4 {
          width: 300px;
          height: 60px;
          font-size: 20px;
          font-weight: bold;
          color: #ffffff;
          line-height: 30px;
        }

        .lableBox {
          height: 23px;
          flex-wrap: wrap;
          overflow: hidden;

          p {
            padding: 2px 6px;
            background: rgba(255, 255, 255, 0.3);
            border-radius: 4px;
            margin-right: 10px;
            text-align: center;
            color: #fff;
            font-size: 14px;
          }
        }
      }

      .bomm {
        align-items: center;
        height: 50px;
        padding: 0 30px;

        h4 {
          font-size: 16px;
          font-weight: bold;
          color: #333333;
        }

        p {
          color: #fff;
          background: #3489ff;
          padding: 4px 6px;
          border-radius: 4px;
        }
      }
    }
  }
}
</style>
